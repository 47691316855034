@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

option {
  color: black;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #47abff;
}

body.chakra-ui-dark::-webkit-scrollbar {
  width: 10px;
}

body.chakra-ui-dark::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body.chakra-ui-dark::-webkit-scrollbar-thumb {
  background-color: #47abff;
}

html.chakra-ui-dark {
  background-color: #162744;
}

.Toastify__toast-container {
  z-index: 200000;
}