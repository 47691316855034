@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
/* LIGHT MODE + GENERAL */
:root {
  --chakra-ui-color-mode: light;
}

.react-calendar {
  border: unset !important;
  background-color: #DFDFDF !important;
  font-family: 'Exo', sans-serif;
  width: 100% !important;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation {
  align-items: center;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: unset;
  text-decoration: unset;
  -webkit-text-decoration: unset;
  -webkit-text-decoration: unset;
  text-decoration: unset !important;
}

.react-calendar__navigation__prev-button {
  background-color: #47abff !important;
  border-radius: 10px;
  min-width: 34px !important;
  height: 34px !important;
  color: white;
}

.react-calendar__navigation__next-button {
  background-color: #47abff !important;
  border-radius: 10px;
  min-width: 34px !important;
  width: 34px !important;
  height: 34px !important;
  color: white !important;
}

.react-calendar__navigation__label {
  font-weight: 700 !important;
  font-size: 18px;
}

.react-calendar__navigation__label:hover,
.react-calendar__navigation__label:focus {
  background-color: unset !important;
  border-radius: 10px !important;
}

.react-calendar__tile {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  height: 34px !important;
  color: #1b2559;
}

.react-calendar__tile:hover {
  border-radius: 10px;
}

.react-calendar__month-view__weekdays {
  background-color: transparent;
  border-radius: 10px !important;
  margin-bottom: 6px;
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #DFDFDF !important;
  border-radius: 10px !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #b0bbd5;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #47abff !important;
  border-radius: 10px !important;
  color: white !important;
}

.react-calendar__tile--range,
.react-calendar__tile--range:enabled:hover,
.react-calendar__tile--range:enabled:focus {
  background: #DFDFDF;
  color: #47abff !important;
  border-radius: 0px;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeStart:enabled:hover,
.react-calendar__tile--rangeStart:enabled:focus {
  background: #47abff !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white !important;
}

.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeEnd:enabled:hover,
.react-calendar__tile--rangeEnd:enabled:focus {
  background: #47abff !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  color: white !important;
}

/* DARK MODE */

body.chakra-ui-dark .react-calendar {
  border-radius: 30px !important;
  background-color: #162744 !important;
}

body.chakra-ui-dark .react-calendar__navigation__prev-button {
  background-color: #47abff !important;
  color: black !important;
}

body.chakra-ui-dark .react-calendar__navigation__next-button {
  background-color: #47abff !important;
  color: black !important;
}

body.chakra-ui-dark .react-calendar__tile {
  color: white !important;
}

body.chakra-ui-dark .react-calendar__tile:enabled:hover,
body.chakra-ui-dark .react-calendar__tile:enabled:focus {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

body.chakra-ui-dark .react-calendar__month-view__weekdays {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

body.chakra-ui-dark .react-calendar__tile--now,
body.chakra-ui-dark .react-calendar__tile--now:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--now:enabled:focus {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

body.chakra-ui-dark .react-calendar__month-view__days__day--neighboringMonth {
  color: #df992f !important;
}

body.chakra-ui-dark .react-calendar__tile--active,
body.chakra-ui-dark .react-calendar__tile--active:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--active:enabled:focus {
  background: #47abff !important;
  color: black !important;
}



body.chakra-ui-dark .react-calendar__tile--range,
body.chakra-ui-dark .react-calendar__tile--range:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--range:enabled:focus {
  background: rgba(255, 255, 255, 0.1) !important;
  color: #47abff !important;
}

body.chakra-ui-dark .react-calendar__tile--rangeStart,
body.chakra-ui-dark .react-calendar__tile--rangeStart:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--rangeStart:enabled:focus {
  background: #47abff !important;
  color: black !important;
}

body.chakra-ui-dark .react-calendar__tile--rangeEnd,
body.chakra-ui-dark .react-calendar__tile--rangeEnd:enabled:hover,
body.chakra-ui-dark .react-calendar__tile--rangeEnd:enabled:focus {
  background: #47abff !important;
  color: black !important;
}
